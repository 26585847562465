import {bindable} from "aurelia-framework";
import {ILocationExtended} from "./bedit-bed-item";
import {IFormSetting} from "../../resources/classes/IFormSettings";

export class beditRoomListItem {
    @bindable room: ILocationExtended;
    @bindable config: IFormSetting;
    @bindable displayCareLevel : boolean;

    get bedWidth() {
        if (!this.room) return "100%;";
        return `${98 / this.room.subItems.length}%`;
    }
}
