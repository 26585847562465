import {autoinject} from "aurelia-framework";
import {I18N} from "aurelia-i18n";
import {DialogController, DialogService} from "aurelia-dialog";
import {SmileService} from "../resources/services/SmileService";
import {UserService} from "../resources/services/UserService";
import {FhirService} from "../resources/services/FhirService";
import {NitTools} from "../resources/classes/NursitTools";
import {DialogMessages} from "../resources/services/DialogMessages";
import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import {App} from "../app";
import {ConfigService} from "../resources/services/ConfigService";

// const environment = require('../../config/environment.json');

@autoinject
export class ChangePassword {
    curPass: string;
    newPass: string;
    confirmPass: string;
    errors: string = " ";
    showCancel: boolean = true;
    userName: string = UserService.UserName;
    userPID: any;
    info: string;

    check() {
        let arr = [];
        if (!this.curPass) arr.push(this.i18n.tr('no_pass_entered'));
        if (!this.newPass || String(this.newPass).trim() === "") arr.push(this.i18n.tr('no_pass_entered'));
        else {
            if (this.confirmPass !== this.newPass) arr.push(this.i18n.tr('pass_no_match'));
            if (!this.confirmPass) arr.push(this.i18n.tr('pass_no_confirm'));
            if (this.newPass) this.newPass = this.newPass.trim();
        }

        return arr.join("<br />\n");
    }

    useAdminUserForPasswordChange: boolean = false;

    async submit() {
        this.errors = ' ';
        let err = this.check();
        if (!err) {
            RuntimeInfo.IsLoading = true;
            let body = {
                "currentPassword": this.curPass,
                "newPassword": this.newPass
            }

            let hash = btoa(`${this.userName}:${this.curPass}`);
            let client = this.rest.getClient(FhirService.AdminEndpoint, hash);

            //const sessionName = environment.sessionName;
            //const curAuth = sessionStorage.getItem(sessionName);

            if (!this.userPID) {
                const pidIdentifier = (UserService.Practitioner.identifier.find(o => o.system.endsWith('/smile-user-pid')));
                if (pidIdentifier) {
                    this.userPID = pidIdentifier.value;
                }
            }

            let updateUrl = 'user-management/password/change';  // see: https://smilecdr.com/docs/json_admin_endpoints/user_management_endpoint.html#update-own-password
            if (this.useAdminUserForPasswordChange && UserService.Practitioner && UserService.Practitioner.identifier) {
                client.configure(x => x.withHeader('Authorization', `Basic ${UserService.AdminUsers.remote}`));
                updateUrl = `user-management/${FhirService.UserNodeId}/${FhirService.UserModuleId}/${this.userPID}/password`; //  - when using UpdatePassword. But that requires UPDATE_USER Permission
            }

            client
                .createRequest(updateUrl)
                .withContent(body)
                .asPut()
                .send()
                .then(() => {
                    sessionStorage.clear();

                    this.app.userService.login(this.userName, this.newPass)
                        .then(async loginResult => {
                            if (loginResult.error)
                                alert(loginResult.error);
                            else if (loginResult.success) {
                                if (UserService.Role && UserService.Role.extension) {
                                    UserService.Role.extension = UserService.Role.extension.filter(o=>!o.url.endsWith('needsPasswordChange'));
                                    if (UserService.Role.extension.length === 0){
                                        delete UserService.Role.extension;
                                    }
                                }

                                try {
                                    await this.fhirService.update(UserService.Role);
                                }
                                catch (e) {
                                    const err = e.message || e;
                                    console.warn(err);
                                    alert(err);
                                }
                                finally {
                                    window.location.reload();
                                }
                            }
                        });
                })
                .catch(error => {
                    let msg : string;
                    if (typeof error === "string") {
                        console.warn(error);
                        msg = error;
                    } else {
                        if (error.response) {
                            let js = JSON.parse(error.response);
                            let errors: string[] = js.messages.map(o => {
                                return o.message
                            });
                            msg = errors.join('<br />\n');
                        } else {
                            if (error.statusCode === 401) {
                                msg = this.i18n.tr('password_unauthorized');
                            } else {
                                msg = JSON.stringify(error);
                            }
                        }
                    }

                    this.dialogMessages.prompt(msg, this.i18n.tr('warning'), true);
                })
                .finally(() => RuntimeInfo.IsLoading = false);
        } else {
            this.errors = err;
        }
    }

    cancel() {
        this.controller.cancel();
    }

    activate(options) {
        if (options && options.isNewUser) {
            this.info = this.i18n.tr('visit_extended_password_explanation');
            this.showCancel = false;
            this.userName = options.userId;
            let params = NitTools.GetUrlParams();
            if (params.pass) {
                this.curPass = params.pass
            } else {
                if (ConfigService.cfg?.features?.autoUserCreation?.enabled === true) {
                    this.curPass = ConfigService.cfg?.features?.autoUserCreation?.defaultPassword || 'Passwort123!';
                }
            }
        } else {
            if (UserService.Practitioner && UserService.Practitioner.identifier) {
                const userPIDIdentifier = UserService.Practitioner.identifier.find(o => o.system && o.system.endsWith('/smile-user-pid'));
                if (userPIDIdentifier && userPIDIdentifier.value)
                    this.userPID = userPIDIdentifier.value;
            }
        }

        this.useAdminUserForPasswordChange = ConfigService.cfg?.features?.useAdminUserForPasswordChange === true;

        if (ConfigService.Debug && this.useAdminUserForPasswordChange) {
            console.warn('Using Admin-User for changing Password. Please Consider changing the Fhir-Config to allow users password change');
        }
    }

    constructor(public controller: DialogController,
                public dialogService: DialogService,
                public dialogMessages: DialogMessages,
                public i18n: I18N,
                public rest: SmileService,
                public app: App,
                public fhirService : FhirService
    ) {
        // http://localhost:9000/user-management?searchTerm=DEBUG2
    }
}
