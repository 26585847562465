import {Router} from "aurelia-router";

export class adminDefaultView {
    static inject = [Router];
    router: Router;

    constructor(router: Router) {
        this.router = router;
    }
}
