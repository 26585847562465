import {autoinject} from "aurelia-framework"
import {ICDService} from "../../../resources/services/ICDService";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";

@autoinject
export class icdDefault {
    constructor(protected icdService: ICDService) {
    }

    async attached() {
        RuntimeInfo.IsLoading = true;
        try {
            //let icds = await this.icdService. ensureConceptMap();
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }
}
