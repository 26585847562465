import {bindable} from "aurelia-framework";
import {ConfigItem} from "./config-item";
import {NitTools} from "../../../resources/classes/NursitTools";
import {DialogService} from "aurelia-dialog";
import {translations} from "../../../resources/classes/translations";
import {Prompt} from "../../../resources/elements/prompt";

export class adminQuestionnaireItem {
    static inject = [DialogService];
    @bindable item: ConfigItem;
    @bindable parent: ConfigItem;
    editing: boolean = false;
    _id: string = NitTools.UidName();
    editValue: ConfigItem;
    dialogService: DialogService;

    constructor(dlg: DialogService) {
        this.dialogService = dlg;
    }

    deleteItem() {
        if (this.parent) {
            this.dialogService.open({
                viewModel: Prompt,
                model: {
                    message: "Really remove the setting completely?",
                    title: translations.translate("information"),
                    showNo: true,
                    yesText: translations.translate('ok')
                },
                lock: true
            })
                .whenClosed(result => {
                    if (!result.wasCancelled) {
                        let idx = this.parent.children.findIndex(o => o.key === this.item.key);
                        if (idx > -1) this.parent.children.splice(idx, 1);
                    }
                });
        }
    }

    startEdit() {
        this.editValue = new ConfigItem(this.item.value, this.item.key);
        this.editing = true;
    }

    abortEdit() {
        this.editValue = undefined;
        this.editing = false;
    }

    commitEdit() {
        this.editing = false;
        this.item.value = this.editValue.value;
        this.item.key = this.editValue.key;
    }
}
