import {bindable, inject} from "aurelia-framework";
import {translations} from "../../resources/classes/translations";

const moment = require("moment");
import {Router} from "aurelia-router";
import {IFormSetting} from "../../resources/classes/IFormSettings";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {ConfigService} from "../../resources/services/ConfigService";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {I18N} from "aurelia-i18n";

@inject(Router, I18N)
export class beditBedItem {
    router;
    public static dateFormat: string = RuntimeInfo.DateFormat;
    @bindable bed: ILocationExtended;
    @bindable displayCareLevel;

    bedChanged(newData: ILocationExtended) {
        this.updateBedDisplay(newData);
    }

    @bindable config: IFormSetting;

    configChanged() {
        this.updateBedDisplay(this.bed);
    }

    @bindable bedwidth: any;
    _encounterId: string;

    constructor(router, i18n: I18N) {
        this.router = router;

        this.textMulti = i18n.tr('multiple_use');
    }

    textMulti: string;

    get encounterId(): string {
        if (!this._encounterId && this.bed && this.bed.subItems && this.bed.subItems[0] && this.bed.subItems[0].encounterId) {
            this._encounterId = this.bed.subItems[0].encounterId;
        }

        return this._encounterId;
    }

    set encounterId(value: string) {
        this._encounterId = value;
    }

    encounter: any;
    multiInfo: string;
    operationalStatus: string = undefined;
    operationalStatusString: string = undefined;

    start: string;
    stop: string;

    updateBedDisplay(newData: ILocationExtended) {
        if (!this.config) return;

        this.encounterId = undefined;
        this.encounter = undefined;
        this.operationalStatus = undefined;
        this.operationalStatusString = undefined;

        if (!newData || !newData.subItems) return;

        if (newData.subItems.length > 0) {
            let arr = [];
            newData.subItems.forEach((patient: PatientItem) => {
                // incorrect way:  let encounterIdentifier = patient.encounter.identifier && patient.encounter.identifier[0] ? ' (' + patient.encounter.identifier[0].value + ')' : '';
                let encounterIdentifier = '';
                if (patient.encounter && patient.encounter.identifier) {
                    let ident: any = patient.encounter.identifier.find(o => o.system?.indexOf('/visitNumber') > -1);
                    if (!ident) ident = patient.encounter.identifier.find(o => o.system?.indexOf('/sourceId') > -1);
                    if (ident) encounterIdentifier = ident.value;
                }

                arr.push(`${patient.display} ${encounterIdentifier}`.trim());
            });

            this.multiInfo = arr.join(',\r\n');
        }

        if (this.config.settings["displayBedStatus"]) {
            if (newData.operationalStatus && newData.operationalStatus.code) {
                this.operationalStatus = newData.operationalStatus.code.toLowerCase();
                if (this.operationalStatus === "O" || this.operationalStatus === "U") {
                    this.operationalStatus = undefined;
                } else {
                    this.operationalStatusString = translations.translate(`operational_status_${this.operationalStatus}`, false);
                }
            }
        }

        if (newData && newData.subItems && newData.subItems[0]) {
            if (newData.subItems[0]["encounter"]) {
                this.encounter = newData.subItems[0]["encounter"];
                this.encounterId = this.encounter.id;

                if (this.encounter.period && this.encounter.period.start) {
                    this.start = moment(this.encounter.period.start).format(beditBedItem.dateFormat);
                }

                if (this.encounter.period && this.encounter.period.end) {
                    this.stop = " - " + moment(this.encounter.period.end).format(beditBedItem.dateFormat);
                }
            }
        }
    }

    get isSuspended(): boolean {
        return this.bed && this.bed.status === fhirEnums.LocationStatus.suspended;
    }

    gotoEncounter() {
        /*if (!this.encounterId && this.bed && this.bed.subItems && this.bed.subItems[0] && this.bed.subItems[0].encounter) {
          this.encounterId = this.bed.subItems[0].encounter.id;
        }*/

        if (this.encounterId) {
            this.router.navigateToRoute('encounter', {id: this.encounterId});
        }
        // App.Router.navigate(`#/${fhirEnums.ResourceType.encounter}/${this.encounterId});
        else {
            if (ConfigService.Debug) console.debug("[BediIt] No Encounter Id in ", this, this.bed);
        }
    }
}

export interface ILocationExtended extends fhir3.Location {
    subItems?: any[];
    hasSubItems?: boolean;
    color?: string;
    label?: string;
}
