import * as Fhir from "../../../../resources/classes/FhirModules/Fhir";

export  class ResourceReindex {
    public resourceTypes: any[] = undefined;
    method : string = "single";
    displayInfo : boolean = false;
    info : string = '';

    async attached() {
        this.resourceTypes = [];
        for (const type of await Fhir.Rest.GetResourceTypes()) {
            this.resourceTypes.push({ name: type, selected: false});
        }
    }

    async sendReindexRequest() {
        const all = this.method === 'all';
        const types = this.resourceTypes.filter(o=>o.selected).map(o=>o.name);

        const result = await Fhir.Rest.$Reindex(types, all)
        if (result.isSuccess) {
            this.info = '<b style="color: green">Marked ' + (all ? 'ALL' : types.length) + ' Resources for reindexing</b>';
        } else {
            this.info = '<b style="color:red">Request was not successful. See Network-Tab.</b>';
        }

        this.displayInfo = true;
        window.setTimeout(() => this.displayInfo = false, 5000);
    }
}
