import {bindable} from "aurelia-framework";
import {ConfigItem} from "./config-item";
import {DialogService} from "aurelia-dialog";
import {Prompt} from "../../../resources/elements/prompt";
import {translations} from "../../../resources/classes/translations";

export class adminQuestionnaireGroup {
    static inject = [DialogService];

    @bindable item: ConfigItem;
    isExpanded: boolean;

    itemChanged(i: ConfigItem) {
        if (i.children) {
            let titleInfo = i.children ? i.children.find(o => o.key === "title" || o.key === "info") : undefined;
            if (titleInfo) {
                this.hint = titleInfo.value;
            }
        } else this.hint = undefined;
    }

    get display(): string {
        return this.item ? this.item.key.toUpperCase() : "UNNAMED";
    }

    newChild: ConfigItem;
    addingChild: boolean = false;
    _newChildType: string;
    hint: string;
    oldName: string = undefined;
    editing: boolean = false;
    dialogService: DialogService;

    constructor(dlg: DialogService) {
        this.dialogService = dlg;
    }

    abortGroupEdit() {
        this.item.key = this.oldName;
        this.editing = false;
    }

    submitGroupEdit() {
        this.editing = false;
    }

    startGroupEdit() {
        this.oldName = this.item.key;
        this.editing = true;
    }

    toggleExpanded() {
        this.isExpanded = !this.isExpanded;
    }

    get newChildType(): string {
        return this._newChildType;
    }

    set newChildType(value: string) {
        this._newChildType = value;
        this.newChild.type = value;
        if (value === 'object') {
            this.newChild.isGroup = true;
            this.newChild.children = [];
        } else {
            this.newChild.isGroup = false;
            this.newChild.children = undefined;
        }
    }

    deleteGroup() {
        if (this.parent) {
            this.dialogService.open({
                viewModel: Prompt,
                model: {
                    message: "Really remove the complete setting group, including the child settings completely?",
                    title: translations.translate("information"),
                    showNo: true,
                    yesText: translations.translate('ok')
                },
                lock: true
            })
                .whenClosed(result => {
                    if (!result.wasCancelled) {
                        let idx = this.parent.children.findIndex(o => o.key === this.item.key);
                        if (idx > -1) this.parent.children.splice(idx, 1);
                    }
                });
        }
    }

    @bindable parent: ConfigItem;

    getJson(): object {
        return this.item.toJS();
    }

    addNewChild() {
        this.addingChild = true;
        this.newChild = new ConfigItem();
    }

    commitChild() {
        this.addingChild = false;
        let child = new ConfigItem(this.newChild.type === "object" ? {} : this.newChild.value, this.newChild.key);

        this.item.children.push(child);
        this.isExpanded = true;
    }

    abortChild() {
        this.addingChild = false;
    }
}
