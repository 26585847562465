import {fhirEnums} from "../../../../resources/classes/fhir-enums";
import {FhirService} from "../../../../resources/services/FhirService";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;

export class SlowPump {
    public sourceServer: string = "http://161.97.139.36:8100";
    public targetServer: string = "http://smilevb:8000";
    public targetType: string = "Encounter";

    public sourceUser: string = "root";
    public targetUser: string = "root";
    public sourcePass: string = "id4admin";
    public targetPass: string = "id4admin";
    public remoteCount: number;
    public currentCount: number;

    getResourceTypes(): string[] {
        //let en = fhirEnums;
        //let nameKeys = [];

        let _keys = Object.values(fhirEnums.ResourceType).filter(o => ["Observation", "List", "SearchParameter", "Questionnaire", "QuestionnaireResponse", "Media",
            "Practitioner", "Patient", "Location", "Encounter", "DomainResource", "Resource", "Organization",
            "Bundle", "ConceptMap", "StructureDefinition", "StructureMap"].indexOf(o) === -1);

        // this should be the 1st resources to pump
        let keys = [/*"SearchParameter", */
            "Organization", "ConceptMap", "CodeSystem", "Location", "Practitioner", "Patient", "Encounter", "Questionnaire", "Observation", "QuestionnaireResponse", "Media"];
        for (const k of _keys) {
            keys.push(k);
        }

        // lists at last please
        keys.push("List");

        keys.sort((a, b) => a.localeCompare(b));

        return keys;
    }

    sourceItems: any[] = [];

    public status: string;
    running: boolean;
    stopOperation: boolean = false;
    btnText = "Start";
    countResult: string = "";

    sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    async compareCounts() {
        const fsSource = new FhirService(this.sourceServer, btoa(`${this.sourceUser}:${this.sourcePass}`));
        const fsLocal = new FhirService(this.targetServer, btoa(`${this.targetUser}:${this.targetPass}`));
        const types = this.getResourceTypes();
        this.countResult = "";
        for (const t of types) {
            const cntRemote = await fsSource.count(t);
            const cntLocal = await fsLocal.count(t);

            this.countResult += `<tr ${cntLocal !== cntRemote ? 'style="color:red"' : ''}><td>${t}</td><td>${cntRemote}</td><td>${cntLocal}</td></tr>`;
        }
    }

    async start() {
        if (this.running) {
            this.stopOperation = true;
        } else {
            this.stopOperation = false;
        }

        if (!this.stopOperation) {
            this.running = true;
            this.status = "Counting...";
            this.sourceItems = [];
            const fsSource = new FhirService(this.sourceServer, btoa(`${this.sourceUser}:${this.sourcePass}`));
            const fsTarget = new FhirService(this.targetServer, btoa(`${this.targetUser}:${this.targetPass}`));

            this.remoteCount = await fsSource.count(this.targetType);
            let pg = 0;
            await fsSource.fetch(`${this.targetType}?_count=250`, true, async (bundle: any) => {
                pg++;
                this.status = "processing resources page " + pg + "/" + Math.round(this.remoteCount / 250);
                if (bundle && bundle.entry)
                    await fsTarget.bundle(bundle.entry.filter(o => typeof o !== 'undefined' && typeof o.resource !== 'undefined').map(o => o.resource), HTTPVerb.put, BundleType.batch, false);

                this.status = "Waiting 2s before next page..";
                this.sleep(2000);
            });

            this.status = "finished loading/pushing " + pg + " Bundle-Pages for " + this.targetType;
            this.running = false;
            this.stopOperation = false;
        }
    }
}
