import {bindable, bindingMode, inject} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {FhirService} from "../../../resources/services/FhirService";
import {ConfigItem} from "./config-item";

@inject(DialogService, I18N, FhirService)
export class adminQuestionnaireView {
    dialogService: DialogService;
    i18n: I18N;
    fhirService: FhirService;
    @bindable({defaultBindingMode: bindingMode.twoWay}) config: any;
    @bindable({defaultBindingMode: bindingMode.twoWay}) result: any;

    root: ConfigItem;

    configChanged(cfg: any) {
        this.root = new ConfigItem({}, "__CONFIG__");
        if (!cfg) return;
        this.root.children = [];

        let tmp: ConfigItem[] = [];
        Object.keys(cfg).sort().forEach(key => tmp.push(new ConfigItem(cfg[key], key)));

        tmp.filter(o => o.type === 'object').forEach(t => this.root.children.push(t));
        tmp.filter(o => o.type !== 'object').forEach(t => this.root.children.push(t));
    }

    getJS() {
        let result = {};
        this.root.children.forEach((item: ConfigItem) => result[item.key] = item.toJS());

        this.result = result;
        return result;
    }

    updatetimerHandle = undefined;

    detached() {
        window.clearInterval(this.updatetimerHandle);
    }

    constructor(dlg, i18, fhir) {
        this.dialogService = dlg;
        this.i18n = i18;
        this.fhirService = fhir;

        this.updatetimerHandle = window.setInterval(() => this.getJS(), 250);
    }
}
