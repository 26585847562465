import {inject} from "aurelia-framework";
import {I18N} from "aurelia-i18n";
import {NitTools} from "../../../resources/classes/NursitTools";
import * as Fhir from "../../../resources/classes/FhirModules/Fhir";
import {FhirService} from "../../../resources/services/FhirService";
import {ConfigService} from "../../../resources/services/ConfigService";
import {DialogMessages} from "resources/services/DialogMessages";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";

@inject(DialogMessages, ConfigService, I18N)
export class ConfigMain {
    configService: ConfigService;
    i18n: I18N;
    fileInput: HTMLInputElement;
    selectedFileName: string;
    configText: string;
    fhirService: FhirService;
    editedConfig: object;
    jsConfig: any = {};
    device: any;
    component: any;
    dialogMessages: DialogMessages;
    public static UseFhirConfig: boolean = false;

    get fhirConfigEnabled(): boolean {
        return ConfigMain.UseFhirConfig;
    }

    constructor(dialogMessages: DialogMessages, configService: ConfigService, i18n: I18N) {
        this.dialogMessages = dialogMessages;
        this.configService = configService;
        this.i18n = i18n;
        this.fhirService = new FhirService();
    }

    async readTextFile(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                return resolve(String(reader.result));
            };

            reader.onerror = () => {
                return reject(reader.error);
            };

            reader.readAsText(this.fileInput.files[0]);
        });
    }

    async saveSettings() {
        try {
            RuntimeInfo.IsLoading = true;
            this.component.productionSpecification = [{
                componentId: {value: ""},
                specType: {
                    coding: []
                }
            }];

            this.component.productionSpecification[0].specType.coding.push({
                id: "jsConfig",
                code: JSON.stringify(this.editedConfig)
            });

            await Fhir.Rest.Update(this.component);
            this.jsConfig = NitTools.Clone(this.editedConfig);

            this.dialogMessages.prompt("Save succeeded.<br />Please don't forget to reload the Application for your changes to take effect!", "HINT");
        } catch (e) {
            let msg = "Error when saving configuration";
            if (e) {
                msg += ": <br/>";
                if (e.message) msg += e.message;
                else msg += JSON.stringify(e);
            } else msg += ".";

            console.warn(msg);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    startParseConfig() {
        if (!this.configText) return;
        this.jsConfig = JSON.parse(this.configText);
    }

    startReadText() {
        this.selectedFileName = this.fileInput.files[0].name;

        this.readTextFile()
            .then((txt: string) => {
                this.configText = txt;
                localStorage.setItem("lastFile", this.configText);

                if (this.configText) {
                    this.startParseConfig();
                }
            })
            .catch(error => {
                this.dialogMessages.prompt(String(error), this.i18n.tr("error"), true);
            });
    }

    startImportConfig() {
        if (this.fileInput.files.length === 0) {
            this.fileInput.click();
        } else {
            this.startReadText();
        }
    }

    async attached() {
        if (!this.fhirConfigEnabled) return;
        this.fileInput.onchange = () => {
            this.startReadText();
        };

        this.device = ConfigService.FhirDevice;
        this.component = ConfigService.FhirDeviceComponent;

        this.configText = this.configService.fhirConfigJson;

        this.startParseConfig();
    }
}
