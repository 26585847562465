import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import {FhirService} from "../resources/services/FhirService";
import {ReportService} from "../resources/services/ReportService";
import {DialogController} from "aurelia-dialog";
import {autoinject} from "aurelia-framework";
import {NitTools} from "../resources/classes/NursitTools";
import {ConfigService} from "../resources/services/ConfigService";

@autoinject
export class ApplicationInfoDialogContent {
    appVersion: string;
    fhir: string;
    report: string;
    controller: DialogController;
    listInfoUrl: string;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    get w(): string {
        return `${window.innerWidth}px`;
    }

    get h(): string {
        return `${window.innerHeight}px`;
    }

    get showResolution(): boolean {
        return ConfigService.Debug;
    }

    get useragent(): string {
        return navigator.userAgent;
    }

    get bodyClasses(): string {
        let result = "";
        document.body.classList.forEach(f => {
            if (f !== "ux-dialog-open")
                result += " " + f + " ";
        })

        return result;
    }

    attached() {
        this.appVersion = RuntimeInfo.AppVersion;
        this.fhir = FhirService.Endpoint;
        this.report = ReportService.ReportServer;
        this.listInfoUrl = NitTools.IncludeTrailingSlash(ReportService.BaseUrl) + 'Info/List';
    }

    openInfoWindow() {
        let w = Math.round((window.innerWidth * 0.75));
        let l = Math.round((window.screenLeft + (w * .2)));
        let h = Math.round((window.innerHeight * 0.75));
        let t = Math.round((window.screenTop + (h * .25)));
        let features = `left=${l}, height=${h}, width=${w}, top=${t}, directories=no,fullscreen=no,location=no,menubar=no,resizable=yes,scrollbars=yes,status=no,titlebar=yes,toolbar=no`;
        window.open(this.listInfoUrl, 'list_window', features);
    }
}
