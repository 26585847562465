import {autoinject} from "aurelia-framework";
import {FhirService} from "../../../resources/services/FhirService";
import {DialogService} from "aurelia-dialog";
import {LocationService} from "../../../resources/services/LocationService";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {fhirEnums} from "../../../resources/classes/fhir-enums";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import {ConfigService} from "../../../resources/services/ConfigService";

@autoinject
export class LocationEditor {
    wards: locationWardClass[] = [];

    async saveChanges() {
        RuntimeInfo.IsLoading = true;
        const resources: any[] = [];
        for (const w of this.wards) {
            resources.push(w.ward);
            for (const r of w.rooms) {
                resources.push(r.room);
                for (const b of r.beds) {
                    resources.push(b.bed);
                }
            }
        }

        await this.fhirService.bundle(resources, HTTPVerb.put, BundleType.batch, false);
        RuntimeInfo.IsLoading = false;
    }

    constructor(protected fhirService: FhirService, protected dialogService: DialogService, protected locationService: LocationService) {
        this.init()
            .catch(err => {
                console.warn(err);
            })
    }

    public async saveRoom(roomItem : locationRoomClass) {
        if (!roomItem || !roomItem.room) return;
        RuntimeInfo.IsLoading = true;

        const btn = <HTMLButtonElement>document.querySelector(`button[data-room-id='${roomItem.room.id}']`);
        try {
            if (btn) btn.setAttribute("disabled", "disabled");
            await this.fhirService.update(roomItem.room, false);
        }
        catch (e) {
            console.warn(e.message || e);
        }
        finally {
            RuntimeInfo.IsLoading = false;
            // update the button with a bit delay to avoid multi clicks on fast Fhir servers
            window.setTimeout(() => {if (btn) btn.removeAttribute("disabled");}, 500);
        }
    }

    public async init() {
        RuntimeInfo.IsLoading = true;
        this.wards = [];
        await this.locationService.fetch(false);
        for (const fhirWard of this.locationService.wards) {
            this.wards.push(new locationWardClass({fhirService: this.fhirService, ward: fhirWard}));
        }

        if (ConfigService.Debug) window["LocationEditor"] = this;
        RuntimeInfo.IsLoading = false;
    }
}

export class locationWardClass {
    ward: any;
    rooms: locationRoomClass[];
    fhirService: FhirService;

    private _expanded: boolean;
    get expanded(): boolean {
        return this._expanded;
    }

    set expanded(value: boolean) {
        this._expanded = value;
        if (this.ward && this._expanded && (!this.rooms || this.rooms.length === 0)) {
            this.rooms = [];
            const url = `Location?_count=200&partof=${this.ward.id}`;
            this.fhirService.fetch(url, true)
                .then((result: any[]) => {
                    for (const r of result)
                        this.rooms.push(new locationRoomClass({
                            fhirService: this.fhirService,
                            room: r
                        }));
                })
        }
    }

    public get name(): string {
        return this.ward?.name;
    }

    constructor(data?: any) {
        this.rooms = [];
        Object.assign(this, data);
    }
}

export class locationRoomClass {
    constructor(data?: any) {
        this.beds = [];
        Object.assign(this, data);
    }

    fhirService: FhirService;
    room: any;
    beds: locationBedClass[];
    extensionBaseUrl: string = 'http://nursit.institute/additional-info';
    colorUrl = 'roomColor';
    labelUrl = 'roomLabel';

    get color(): string {
        if (!this.room || !this.room.extension) return undefined;
        const colExtension = this.room.extension.find(o => o.url.endsWith(this.colorUrl));
        if (!colExtension) return undefined;

        return colExtension.valueString;
    }

    set color(value: string) {
        if (!this.room) return;

        if (!this.room.extension) this.room.extension = [];
        let colExtension = this.room.extension.find(o => o.url.endsWith(this.colorUrl));
        if (value) {
            if (!colExtension) {
                colExtension = {url: `${this.extensionBaseUrl}/${this.colorUrl}`};
                this.room.extension.push(colExtension);
            }

            if (colExtension) colExtension.valueString = value;
        } else {
            if (colExtension) {
                const idx = this.room.extension.indexOf(colExtension);
                this.room.extension.splice(idx, 1);
            }
        }

        if (this.room.extension && this.room.extension.length === 0)
            delete this.room.extension;
    }

    get label(): string {
        if (!this.room || !this.room.extension) return undefined;
        const roomExtension = this.room.extension.find(o => o.url.endsWith(this.labelUrl));
        if (!roomExtension) return undefined;
        return roomExtension.valueString;
    }

    set label(value: string) {
        if (!this.room) return;
        if (!this.room.extension) this.room.extension = [];
        let labelExtension = this.room.extension.find(o => o.url.endsWith(this.labelUrl));

        if (value) {
            if (!labelExtension) {
                labelExtension = {url: `${this.extensionBaseUrl}/${this.labelUrl}`};
                this.room.extension.push(labelExtension);
            }

            if (labelExtension) labelExtension.valueString = value;
        } else {
            if (labelExtension) {
                const idx = this.room.extension.indexOf(labelExtension);
                this.room.extension.splice(idx, 1);
            }
        }
    }

    private _expanded: boolean;
    get expanded(): boolean {
        return this._expanded;
    }

    set expanded(value: boolean) {
        this._expanded = value;
        if (this.room && this._expanded && (!this.beds || this.beds.length === 0)) {
            this.beds = [];
            const url = `Location?_count=200&partof=${this.room.id}`;
            this.fhirService.fetch(url, true)
                .then((result: any[]) => {
                    for (const b of result) this.beds.push(new locationBedClass({expanded: false, bed: b}));
                })
        }
    }

    public get name(): string {
        return this.room?.name;
    }
}

export class locationBedClass {
    bed: any;
    expanded: boolean = false;

    constructor(data?: any) {
        Object.assign(this, data);
    }
}
