import {autoinject, bindable} from "aurelia-framework";
import {ConfigService} from "../../resources/services/ConfigService";
import * as moment from "moment";
import {NitTools} from "../../resources/classes/NursitTools";
import {Tools} from "../../resources/classes/FhirModules/Fhir";
import {FhirService} from "../../resources/services/FhirService";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import { I18N } from "aurelia-i18n";

@autoinject
export class beditPatientInfo { // extends MarkedPatientItem {
    @bindable displayCareLevel : boolean;
    @bindable patient: any;

    dischargeColor: string = 'white';
    dischargePercentString: string = undefined;
    dischargeTitle: string = undefined;
    hasDischarge: boolean = false;
    careLevelTitle: string = "";
    spi: number;

    static _assessmentRedAfter: number = undefined;
    static _assessmentYellowAfter: number = undefined;
    static _dischargeEnabled = undefined;
    static _showDwellTime = undefined;
    static _maxIsoAge: number = undefined;

    _duration = undefined;
    _elementPatient: PatientItem;

    get duration(): string {
        return this._duration || '-';
    }

    get elementPatient(): PatientItem {
        return this._elementPatient;
    }

    set elementPatient(patient: PatientItem) {
        this._elementPatient = patient;
        let clTtle = patient && patient.careLevel > -1 ? Tools.CareLevelToText(this.elementPatient.careLevel) : this.i18n.tr("without");
        this.careLevelTitle = `${this.i18n.tr("carelevel")}: ${clTtle}`;
    }    

    get showDwellTime(): boolean {
        if (typeof beditPatientInfo._showDwellTime === "undefined") {
            let setting = ConfigService.GetFormSettings("ward");
            if (setting && setting.settings) {
                beditPatientInfo._showDwellTime = setting.settings.showDwellTime;
            }
        }

        return beditPatientInfo._showDwellTime;
    }

    static get maxIsoAge() {
        if (!this._maxIsoAge) {
            let setting = ConfigService.GetFormSettings(ConfigService.FormNames.Isolation);
            this._maxIsoAge = setting && setting.expiration && typeof setting.expiration.default !== "undefined" ? setting.expiration.default : 23;
        }

        return this._maxIsoAge;
    }

    get showDischarge(): boolean {
        if (typeof beditPatientInfo._dischargeEnabled === "undefined") {
            let setting = ConfigService.GetFormSettings("discharge");
            if (setting) {
                beditPatientInfo._dischargeEnabled = setting.enabled;
            }
        }

        return beditPatientInfo._dischargeEnabled;
    }

    constructor(protected i18n : I18N) {
    }

    private findFlag(system: string): any {
        let result: any = undefined;
        if (this.patient && this.patient.flags
            && this.patient.flags.code && this.patient.flags.code.coding) {
            const coding = this.patient.flags.code.coding;
            result = coding.find(o => o.system.endsWith('/' + NitTools.ExcludeTrailingSlash(system)));
        }

        return result;
    }

    async patientChanged(patient : PatientItem) {
        this.elementPatient = patient;


        if (patient) {
            await ConfigService.LoadConfigOverride(patient?.ward, patient);
        //displayCareLevel
            let dwellTime = undefined;
            if (patient.encounter && patient.encounter.period && patient.encounter.period.start) {
                dwellTime = moment(patient.encounter.period.end || new Date())
                    .diff(patient.encounter.period.start, "d");

                if (dwellTime) this._duration = dwellTime + ' ' + this.i18n.tr("days");
            }

            /*     const spiTag = FhirService.Tags.get(patient.encounter, 'nursit-institute.com/structureDefinitions/tags/SPI');
                 if (spiTag && spiTag.code) {
                     this.spi = parseInt(spiTag.code);
                     if (this.spi) this.patient.spiSum = this.spi;
                     else this.spi = this.patient.spiSum;
                 }  */

            if (this.patient.flags && this.patient.flags.code && this.patient.flags.code.coding) {
                let flagActive = this.findFlag('discharge-active');
                let flagPercent = this.findFlag('discharge-percent');
                let flagColor = this.findFlag('discharge-color');

                this.dischargePercentString = flagPercent ? flagPercent.code : undefined;
                this.dischargeColor = flagColor ? flagColor.code || 'white' : 'white';
                this.hasDischarge = flagActive ? NitTools.ParseBool(flagActive.code) : false;
                this.dischargeTitle = this.hasDischarge ? `${this.i18n.tr("release_management")}: ${this.dischargePercentString}` : this.i18n.tr("no_active_discharge_plan");

                this.patient.mark_5_class = 'active-red';

                const tag5 = FhirService.Tags.get(this.patient.encounter, 'tags/mark5');
                if (tag5) this.patient.mark_5_class = tag5.code;
            }
        }
    }
}
