import {Router} from 'aurelia-router';
import {autoinject, bindable, TaskQueue} from "aurelia-framework";
import {ILocationExtended} from "./bedit-bed-item";
import {NitTools} from "../../resources/classes/NursitTools";
import {translations} from "../../resources/classes/translations";
import {IFormSetting} from "../../resources/classes/IFormSettings";
import {ConfigService} from "../../resources/services/ConfigService";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {FhirService} from "../../resources/services/FhirService";
import {PatientService} from "../../resources/services/PatientService";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import {App} from "../../app";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {LocationService} from "../../resources/services/LocationService";
import {I18N} from "aurelia-i18n";
import {Tools} from "../../resources/classes/FhirModules/Fhir";

const moment = require("moment");

@autoinject
export class beditMain {
    @bindable wardIdBinded

    router: Router;
    patientService: PatientService;
    fhirService: FhirService;
    wardId: string = undefined;
    ward: any = undefined;
    patientItems: PatientItem[];

    get patients(): PatientItem[] {
        return this.patientItems || [];
    }

    qList: IQuestionnaireList;
    rooms: ILocationExtended[] = [];
    viewData: ILocationExtended[] = [];
    configService: ConfigService;
    wardName: string = '';
    moreInfo: string = '';
    beds: ILocationExtended[] = [];
    patientsWithNoBed: any[] = [];
    _showNoBedPatientList: boolean = false;
    get showNoBedPatientList(): boolean {
        return this._showNoBedPatientList;
    }

    set showNoBedPatientList(value: boolean) {
        this._showNoBedPatientList = value;
        const className = 'no-beds-list-visible';

        if (!value) document.body.classList.remove(className)
        else document.body.classList.add(className);
    }

    timerHandle;
    timeUntilRefresh: number;
    refreshTime: number;
    countDownTimer: number;
    taskQueue: TaskQueue;
    refreshInfo: string;
    nextRefrehInfo: string;
    config: IFormSetting;
    app: App;

    constructor(router: Router, taskQueue: TaskQueue, fhirService: FhirService, patientService: PatientService, app: App, protected locationService: LocationService,
                protected i18n: I18N) {
        this.router = router;
        this.taskQueue = taskQueue;
        this.fhirService = fhirService;
        this.patientService = patientService;
        this.app = app;
    }

    displayCareLevel: boolean;

    toggleNoBedPatientList() {
        if (!this.showNoBedPatientList) {
            if (this.patientItems)
                this.patientsWithNoBed = this.patientItems.filter(o => !o.bedId);
            else
                this.patientsWithNoBed = [];
        }

        this.showNoBedPatientList = !this.showNoBedPatientList;
    }

    gotoEncounter(patient) {
        if (patient && patient.encounter && patient.encounter.id) {
            this.router.navigateToRoute('encounter', {id: patient.encounter.id});
        }
    }

    onmouseenter() {
        let pos = $("#spiInfoSpan").position();
        if (!pos) {
            pos = {left: 100, top: 10};
        }

        pos.top = 60;
        const $ele = $(".bedit-cl-info-overlay");
        $ele.css({left: pos.left + 50, top: pos.top, opacity: 0, display: 'block'}).animate({opacity: 1}, "0.2s");
    }

    mouseleave() {
        $(".bedit-cl-info-overlay").hide(0.5);
    }

    async attached() {
        if (this.wardIdBinded) {
            this.wardId = this.wardIdBinded
            RuntimeInfo.CurrentWardId = this.wardId;
        } else {
            if (RuntimeInfo.CurrentWardId) this.wardId = RuntimeInfo.CurrentWardId;
        }

        if (this.wardId)
            this.ward = LocationService.LocationById(this.wardId);

        const cfg = ConfigService.GetFormSettings('ward');
        if (cfg && cfg.settings && typeof cfg.settings.displayCareLevel === 'boolean') {
            this.displayCareLevel = cfg.settings.displayCareLevel;
        } else {
            this.displayCareLevel = true;
        }

        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.BedOverview);
        this.refreshTime = 60000 * (this.config.settings["refreshMinutes"] || 3);
        this.timeUntilRefresh = this.refreshTime;

        document.body.classList.add("no-toolbar-window");

        this.taskQueue.queueTask(async () => {
            NitTools.fixScrollLock(".bed-it .scroll-container");

            await this.loadData(false);
        });

        this.countDownTimer = window.setInterval(() => {
            this.timeUntilRefresh -= 1000;
            if (this.timeUntilRefresh <= 0) {
                this.timeUntilRefresh = this.refreshTime;
            }

            this.nextRefrehInfo = translations.translate("bed_overview_refresh_info");

            let seconds = (this.timeUntilRefresh - 1000) / 1000;

            if (seconds > 60) {
                let minutes = seconds / 60;
                minutes = parseInt(minutes.toString().split('.')[0]);
                seconds -= minutes * 60;
                this.nextRefrehInfo += `${minutes} ${translations.translate("minutes")}, ${seconds} ${translations.translate('seconds')}`;
            } else {
                this.nextRefrehInfo += `${seconds} ${translations.translate('seconds')}`;
            }
        }, 1000);

        if (ConfigService.Debug) {
            window["bedit"] = this;
        }
    }

    clInfos: ICareLevelSummary[] = [];

    async loadData(forceRefresh: boolean = true) {
        RuntimeInfo.IsLoading = true;
        if (forceRefresh) PatientItem.ClearListResult();
        // to be clean, remove the existing timerhandle
        if (this.timerHandle) {
            window.clearTimeout(this.timerHandle);
        }

        this.timeUntilRefresh = this.refreshTime;

        try {
            await QuestionnaireService.Fetch();
            this.rooms = await this.loadRooms();

            this.app.selectedWard = this.locationWard;
            RuntimeInfo.CurrentWardId = this.locationWard.id;

            this.patientItems = await PatientItem.List(this.fhirService, this.locationService, this.locationWard.id); //  (await this.patientService.list( RuntimeInfo.CurrentWardId )).slice(); // this.app.loadPatients();

            this.rooms.forEach(room => {
                room.subItems.forEach((bed: ILocationExtended) => {
                    bed.subItems = this.patientItems.filter(o => o.bedId === bed.id);
                });
            });

            this.refreshInfo = moment(new Date()).format(RuntimeInfo.DateTimeFormat);
            this.clInfos = [];

            for (let i = -1; i < 4; i++) {
                let infoItem = this.clInfos.find(o => o.lvl === i);
                if (!infoItem) {
                    infoItem = {
                        lvl: i,
                        count: 0,
                        middle: 0,
                        sum: 0,
                        text: this.i18n.tr(i === -1 ? 'without' : 'carelevel' + i),
                        bgcolor: Tools.CareLevelToColor(i),
                        fgcolor: Tools.CareLevelToTextColorInvert(i)
                    };

                    this.clInfos.push(infoItem);
                }
            }

            let spiSum = 0;
            let patientCount = 0;
            //let dbgString = "";
            let arr = [];

            this.patientItems.forEach(p => {
                let infoItem: ICareLevelSummary = this.clInfos.find(o => o.lvl === p.careLevel);
                if (!infoItem) {
                    infoItem = {
                        lvl: p.careLevel,
                        count: 0,
                        middle: 0,
                        sum: 0,
                        text: this.i18n.tr("carelevel" + p.careLevel),
                        fgcolor: Tools.CareLevelToTextColorInvert(p.careLevel),
                        bgcolor: Tools.CareLevelToColor(p.careLevel)
                    };
                    this.clInfos.push(infoItem);
                }

                if (infoItem) infoItem.count++;

                if (p.careLevel > -1 || p.SPI) {
                    spiSum += p.SPI;
                    arr.push(p.SPI);
                    patientCount++;

                    if (infoItem) {
                        infoItem.sum += p.SPI;
                        infoItem.middle = infoItem.sum / infoItem.count;
                    }
                }
            });

            const durchschnitt = spiSum / patientCount;
            /* dbgString = '(' + this.patientItems.length + ' Patients total on Ward, ' + arr.length + ' with SPI values) => ' + arr.join(' + ') + ' => ' + spiSum + ' / ' + patientCount + ' => ' + durchschnitt;
            if (ConfigService.Debug)
                console.debug(dbgString); */

            this.moreInfo = `&Oslash; ${this.i18n.tr("spi_short")}: ${isNaN(durchschnitt) ? '0' : durchschnitt.toFixed(1)}`;

            this.timerHandle = window.setTimeout(async () => {
                await this.loadData();
            }, this.refreshTime);
        } catch (e) {
            console.error(e.message || e)
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    locationWard: any;

    async loadRooms(): Promise<ILocationExtended[]> {
        // capture and save the ward, then remove it from the array
        const ward = LocationService.LocationById(this.wardId);
        if (ward) {
            this.wardName = ward.name;
            this.locationWard = ward;
        }

        // load the beds for the wardId location
        let roomsBundle = <any[]>await this.fhirService.fetch("Location?partof=" + this.wardId + "&status:not=inactive");
        if (this.wardName) {
            roomsBundle.forEach(r => {
                if (r.name.startsWith(this.wardName)) {
                    r.name = r.name.substr(this.wardName.length).trim();
                }
            })
        }
        roomsBundle.sort((a, b) => {
            return a.name.localeCompare(b.name)
        });

        this.beds = [];

        for (const room of roomsBundle) {
            let bedBundle = <any[]>await this.fhirService.fetch("Location?partof=" + room.id + "&status:not=inactive");
            if (bedBundle.length > 0)
                for (const bed of bedBundle) {
                    this.beds.push(bed);
                }
            else {
                console.warn(`No Bed found in Room ${room.name} (id: ${room.id})`);
            }
        }

        this.beds.sort((a: any, b: any) => {
            if (a.name && b.name) {
                return a.name.localeCompare(b.name);
            }
        });


        // do some nice sorting
        roomsBundle.sort((a: any, b: any) => {
            if (a.name && b.name) {
                return a.name.localeCompare(b.name);
            }
        });

        // assign the beds to the rooms
        const _rooms: ILocationExtended[] = roomsBundle;
        if (this.beds) {
            _rooms.forEach(room => {
                room.subItems = this.beds.filter(o => o.partOf && o.partOf.reference.endsWith(room.id));
                room.hasSubItems = room.subItems && room.subItems.length > 0;
                if (room && room.extension) {
                    try {
                        const colorExtension = room.extension.find(o => o.url.endsWith('roomColor'));
                        if (colorExtension && colorExtension.valueString) room.color = colorExtension.valueString;

                        const labelExtension = room.extension.find(o => o.url.endsWith('roomLabel'));
                        if (labelExtension && labelExtension.valueString) room.label = labelExtension.valueString;
                    } catch (err) {
                        console.warn(err.message || err);
                    }
                }
            });
        }

        const result = _rooms.filter(o => o.physicalType && o.physicalType.coding && o.physicalType.coding[0] && o.physicalType.coding[0].code == "ro"); // .filter(o => o.hasSubItems);

        return result;
    }

    detached() {
        this.showNoBedPatientList = false;
        NitTools.releaseScrollLock(".bed-it .scroll-container");
        document.body.classList.remove("no-toolbar-window");
        if (this.timerHandle) {
            window.clearTimeout(this.timerHandle);
        }

        if (this.countDownTimer) {
            window.clearTimeout(this.countDownTimer);
        }
    }
}

export interface ICareLevelSummary {
    lvl: number;
    count: number;
    middle: number;
    sum: number;
    text: string;
    bgcolor: string;
    fgcolor: string;
}
