import {bindable} from "aurelia-framework";
import {ConfigItem} from "./config-item";
import {NitTools} from "../../../resources/classes/NursitTools";

export class adminItemEdit {
    @bindable item: ConfigItem;
    @bindable allowKeyEdit: boolean = false;
    idString: string = NitTools.UidName();
    idObject: string = NitTools.UidName();
    idNumber: string = NitTools.UidName();
    idBoolean: string = NitTools.UidName();
}
