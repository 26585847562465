import {FhirService} from "../../../../resources/services/FhirService";
import {Tools, QuestionnaireResponse} from "../../../../resources/classes/FhirModules/Fhir";
import {NitTools} from "../../../../resources/classes/NursitTools";
import * as environment from "../../../../../config/environment.json";

export class AssessmentLinkCheck {
    totalAssessments: number = 0;
    assessmentsCount: number = 0;
    checkingAssessments: boolean = false;
    fhirService: FhirService;
    status: string;
    baseUrl = '';
    assessments: any[] = [];

    constructor() {
        this.fhirService = new FhirService();
    }

    checkAssessmentExtensions(assessment: any) {
        let getUrl = function (url) {
            return `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/${url}`;
        };

        let warnings = [];

        const biUrl = getUrl('questionnaire-link/barthelindex');
        const biExUrl = getUrl('questionnaire-link/barthelindex-extended');
        const fimUrl = getUrl('questionnaire-link/fim');

        let biItem = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'BarthelIndex', false);
        let biExItem = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'BarthelIndexEx', false);
        let fimItem = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'FIM', false);

        if (biItem) {
            let val = QuestionnaireResponse.GetResponseItemValue(biItem);
            if (val) {
                Tools.SetExtension(assessment, biUrl, val);
            } else {
                warnings.push('BarthelIndex Item exists, but has no value');
            }
        } else {
            warnings.push('No BarthelIndex-Item present in Assessment');
        }

        if (biExItem) {
            let val = QuestionnaireResponse.GetResponseItemValue(biExItem);
            if (val) {
                Tools.SetExtension(assessment, biExUrl, val);
            } else {
                warnings.push('BarthelIndexEx Item exists, but has no value');
            }
        } else {
            warnings.push('No BarthelIndexEx-Item present in Assessment');
        }

        if (fimItem) {
            let val = QuestionnaireResponse.GetResponseItemValue(fimItem);
            if (val) {
                Tools.SetExtension(assessment, fimUrl, val);
            } else {
                warnings.push('FIM Item exists, but has no value');
            }
        } else {
            warnings.push('No FIM-Item present in Assessment');
        }

        return {
            warnings: warnings,
            complete: warnings.length === 0,
            assessment: assessment
        }
    }

    async checkAssessment(assessment: any) {
        // simplest way: copy the ids of barthelindex, biEx and FIM to the extension
        let biId = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'BarthelIndex', false);
        let biExId = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'BarthelIndexEx', false);
        let fimId = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'FIM', false);

        if (biId && biExId && fimId) {
            let result = this.checkAssessmentExtensions(assessment);
            if (!result.complete) {
                console.warn("Not completed Link-conversion:", result);
            }
        }

    }

    async checkAssessmentLinks() {
        if (!this.assessments) this.assessments = [];
        this.assessmentsCount = 0;
        this.checkingAssessments = true;
        this.status = "Downloading all Assessments...";
        if (this.assessments.length === 0) {
            this.assessments = await this.fhirService.fetch(this.baseUrl + "&_count=250", true, (r: any) => {
                this.assessmentsCount += r.entry.length;
                this.status = `Downloaded Assessments ${this.assessmentsCount}/${this.totalAssessments}.`;
            });
        }

        for (let i = 0; i < this.assessments.length; i++) {
            this.status = `Checking Assessment (${i}/${this.assessments.length})`;
            this.checkAssessment(this.assessments[i]);
        }

        window.setTimeout(() => {
            this.checkingAssessments = false;
            this.status = "Fixing done.";
        }, 2000);
    }
}
